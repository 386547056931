.custom-header {
	background-image: url(https://assistnow.com.au/assets/images/logo.png);
	height: 50px;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center center;
}

.custom-card {
	padding: 5px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.submit-btn {
	margin-top: 10px;
	margin-bottom: 10px;
}

.star {
	color: gray;
	font-size: 2rem;
}

.review-row {
	background-color: #eeeeee;
	margin-bottom: 10px;
	padding-top: 10px;
	border: 1px solid black;
	border-radius: 5px;
}

.reply-row {
	margin-bottom: 2px;
	padding-top: 2px;
}

.provider-row {
	background-color: #eeeeee;
	border: 1px solid black;
	border-radius: 5px;
	margin-top: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.provider-cont {
	margin-top: 30px;
}

.provider-cont > a {
	text-decoration: none;
	color: black;
}

.user-image {
	max-width: 50px;
}

.dislike-btn {
	margin-left: 30px;
}

.icons {
	margin-right: 4px;
}

.comment {
	padding-top: 10px;
}

.reply {
	padding-top: 2px;
}

.reply > p {
	margin: 2px;
}

.review-time {
	float: right;
}

.service-review-cont {
	margin-top: 30px;
	padding: 10px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.provider-name::first-letter {
  text-transform: uppercase;
}

.detail {
	margin-top: 100px;
}

.detail-label {
	color: #8ac33d;
}

.comment-origin {
	margin-left: 10px;
	padding: 5px 10px;
	border: 1px solid black;
	border-radius: 20px;
}

.comment-origin::first-letter {
  text-transform: uppercase;
}

.replyTextBox {
	margin-bottom: 10px;
}
body {
	font-family: 'Open Sans', sans-serif;
}
/* Header Style */
.header-wrap {
	border-bottom: 1px solid #dadada;
}
.header-wrap .navbar {
	padding: 20px 0 25px 0;
}
.header-wrap .btn {
	border: 1px solid #000000;
	padding: 5px 35px;
	font-weight: 600;
	border-radius: 12px;
	color: #000;
}
.header-wrap .btn:hover {
	color: #fff;
}
/* Provider Page Style */
.provider-wrap {
	padding: 30px 0;
}
.provider-info {
	padding-bottom: 20px;
}
.provider-name {
	font-size: 32px;
	font-weight: 600;
	color: #000;
}
.provider-category {
	font-size: 18px;
	color: #191919;
}
.provider-rating {
	font-size: 16px;
	color: #191919;
	padding-bottom: 10px;
	flex-wrap: wrap;
}
.provider-rating .rating-count {
	font-size: 32px;
	font-weight: 600;
	color: #000;
	line-height: 1;
	position: relative;
	top: 2px;
}
.provider-rating .star-wrap {
	top: -2px;
	position: relative;
}
.provider-contact-info li {
	position: relative;
	padding: 0 0 0 35px;
	margin-bottom: 6px;
}
.provider-contact-info li .detail-label {
	position: absolute;
	left: 0;
	top: 0;
}
.provider-rating-avg {
	box-shadow: none;
	padding: 15px 0 0 0;
	margin: 0;
	border-top: 1px solid #e4e4e4;
}
.give-rating {
	margin-top: 15px;
	padding-top: 40px;
	border-top: 1px solid #e4e4e4;
}
.give-rating h5 {
	font-size: 18px;
	font-weight: 600;
}
.give-rating textarea.form-control {
	height: 150px;
	border-radius: 10px;
}
.give-rating .submit-btn .btn {
	background-color: #8ac33d;
	border-color: #73a233;
	font-size: 18px;
	font-weight: 700;
	margin-top: 10px;
	border-radius: 10px;
	padding: 5px 25px;
}
.star-wrap .star {
	color: #fff;
	background-color: #d8d8d8;
	border-radius: 4px;
	height: 22px;
	width: 22px;
	display: inline-block;
	line-height: 0.65;
	font-size: 23px;
	text-align: center;
	padding: 2px 0;
	margin: 0 2px;
}
.user-review-section {
	border: 1px solid #e4e4e4;
}
.user-review-section h2 {
	background-color: #f4f4f4;
	padding: 19px 25px;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 2px;
}
.user-review-section .load-more-btn {
	width: 100%;
	background: transparent;
	border: none;
	color: #333;
	font-weight: 600;
	margin: 0 auto 11px auto;
	display: block;
}
.user-review-item {
	padding: 10px 20px 15px 20px;
	border-bottom: 1px solid #e4e4e4;
	margin-bottom: 10px;
}
.user-review-item .user-image {
	border-radius: 50%;
	border: 1px solid #ccc;
}
.user-review-item h5 {
	font-size: 19px;
	font-weight: 600;
}
.user-review-item p {
	font-size: 14px;
	margin-bottom: 10px;
}
.review-action > span {
	display: inline-block;
	text-align: center;
	padding: 0 8px;
}
.review-action>span>svg {
	height: 24px;
}
.review-action>span>span {
	display: block;
}
.user-review-time {
	color: #666666;
	font-size: 13px;
	display: block;
}
.reply-wrap {
	padding: 12px 2px 0 108px;
}
.reply-wrap .user-image {
	max-width: 50px;
	border-radius: 50%;
	border: 1px solid #ccc;
}
.reply-wrap h6 {
	font-size: 17px;
	font-weight: 600;
}
.reply-wrap .reply {
	color: #666666;
	font-size: 13px;
}
.reply-wrap .reply-row {
	border-top: 1px solid #e4e4e4;
	padding-top: 12px;
}
.reply-wrap .reply-row:first-child {
	border-top: none;
}
.reply-field .btn {
	background-color: #8ac33d;
	border-color: #73a233;
	font-size: 14px;
	font-weight: 700;
	margin-top: 10px;
	border-radius: 10px;
	padding: 5px 25px;
}
@media (max-width: 575px) {
	.user-review-item>.row> .col,
	.reply-row>.row>.col {
		flex-shrink: 0;
		width: 100%;
		max-width: 100%;
		flex: none;
	}
	.reply-wrap {
		padding-left: 0;
	}
}